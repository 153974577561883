@font-face {
    font-family: 'facit';
    src: url('./assets/fonts/facit-regular.eot');
    src: url('./assets/fonts/facit-regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/facit-regular.woff') format('woff'),
        url('./assets/fonts/facit-regular.ttf') format('truetype');
}

@font-face {
    font-family: 'facit';
    src: url('./assets/fonts/facit-light.eot');
    src: url('./assets/fonts/facit-light.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/facit-light.woff') format('woff'),
        url('./assets/fonts/facit-light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'facit';
    src: url('./assets/fonts/facit-semibold.eot');
    src: url('./assets/fonts/facit-semibold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/facit-semibold.woff') format('woff'),
        url('./assets/fonts/facit-semibold.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'facit';
    src: url('./assets/fonts/facit-bold.eot');
    src: url('./assets/fonts/facit-bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/facit-bold.woff') format('woff'),
        url('./assets/fonts/facit-bold.ttf') format('truetype');
    font-weight: bold;
}

$font-family-base: 'facit';
$border-radius: 0;
$primary: #ff4e00;
$danger: red;
$link-decoration: 'underline';
$link-color: $primary;
$link-hover-color: $primary;
$dropdown-padding-y: 0;
$dropdown-item-padding-x: 0.75rem;
$card-cap-bg: #fff;
$card-border-color: #000;
$lead-font-weight: 400;
$tooltip-max-width: 400px;
$tooltip-padding-x: 0.75rem;
$tooltip-padding-y: 0.75rem;

$tooltip-arrow-width: 1.5rem;
$tooltip-arrow-height: 1.2rem;

$theme-colors: (
    'autobizCarcheck': #f15a24,
    'autobizTrade': #00693b,
    'autobizMarket': #39b54a,
    'autobizMonStock': #602483,
    'autobizIframe': #c00f22,
    'autobizClic2Sell': #006ab0,
    'autobizAPI': #009a9a,
    'autobizAcademy': #f6a000,
);

@import '~bootstrap/scss/bootstrap';

$gutter-half: $grid-gutter-width / 2;
