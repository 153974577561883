@import 'head.scss';

// header
header {
    margin: $gutter-half 0;
    img {
        width: 150px;
    }
}

.hasFixedHeader {
    padding-top: 49px;
}
.preferencesMessage {
    padding: 10px;
    margin-top: 49px;
    background-color: #d9d9d9;
    color: black;
    font-size: 12px;
    text-align: center;
}
.hr-line {
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
    &:before,
    &:after {
        content: '';
        flex: 1 1;
        border-bottom: 1px solid #464646;
        margin: auto;
    }
    &:before {
        margin-right: $gutter-half;
    }
    &:after {
        margin-left: $gutter-half;
    }
}

.home-info {
    background-color: #d7f0db;
    padding: 1.5rem;
    img {
        width: 50%;
        float: right;
        margin: 0.5em 0 0.5em 0.5em;
    }

    button {
        width: 100%;
        @include media-breakpoint-up(sm) {
            width: 50%;
        }
        @include media-breakpoint-up(lg) {
            width: 100%;
        }
        @include media-breakpoint-up(xl) {
            width: 50%;
        }
    }
}

// Footer
footer {
    font-size: 0.875rem;
    display: flex;
    margin: $grid-gutter-width 0 $gutter-half 0;
    justify-content: space-between;
    @include media-breakpoint-down(md) {
        flex-direction: column;
        height: 100%;
    }
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .dropdown-toggle {
        background-color: transparent !important;
        color: $body-color !important;
        border-color: transparent !important;
        box-shadow: none !important;
        padding-left: 0;
        padding-right: 0;
    }
    .lang-flag {
        width: 20px;
    }
    .dropdown-menu {
        min-width: 50px !important;
    }

    .footer-link {
        flex-direction: row;
        a {
            display: block;
            color: #525252;
        }
        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: row;
            align-items: center;
            a:not(:last-child):after {
                content: '|';
                padding: 0 3px;
                color: #525252;
            }
        }
    }

    .link:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    @include media-breakpoint-up(lg) {
        .dropdown {
            order: 1;
        }
        .footer-link {
            order: 2;
        }
        .copyright {
            order: 3;
        }
    }
}

// Spinner
.loader-full-screen {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: lightgrey;
    left: 0;
    opacity: 0.4;
    z-index: 2000;
    .spinner-border {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5rem;
        height: 5rem;
    }
    &.main {
        background-color: transparent;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            height: 160px;
            width: 160px;
        }
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            div {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                .spinner-grow {
                    height: 1rem;
                    width: 1rem;
                    margin: 0 15px;
                }
            }
        }
    }
}

.page.page-login {
    .container {
        display: flex;
        min-height: 100vh;
        flex-direction: column;
    }
    main {
        margin: auto;
    }
    .title {
        font-weight: 400;
        font-size: 1.5rem;
        @include media-breakpoint-up(sm) {
            font-size: 1.65rem;
        }
    }
}

.posts-list {
    .card {
        display: flex;
        height: 100%;
        .card-footer {
            border-top: 0;
            a {
                text-decoration: none;
                color: $body-color;
                font-weight: 500;
            }
        }
    }
}

.error-message {
    @extend .invalid-feedback;
    display: block;
}

.error-control {
    border-color: $danger !important;
}

.lang-icon {
    position: relative;
    float: right;
    padding-right: 14px;
}

.icon-flag {
    width: 20px;
    margin-bottom: 2px;
}

.menu-container {
    position: absolute;
    right: 0;
    background-color: #eff3ff;
    display: none;
    z-index: 11;
    margin-top: -10px;

    &.open {
        display: block;
    }
    ul {
        list-style-type: none;
        text-align: left;

        margin: 0;
        padding: 0px 20px 15px 25px;
        li {
            padding: 4px 0;
            a,
            a:not([href]):not([tabindex]) {
                text-decoration: none !important;
                color: #000 !important;
            }
        }
    }
}

.databox {
    border: 1px solid #000;
    padding: 0.4em;
    @extend .h-100;
    background-color: #fff;
    text-align: center;
    display: block;
    color: #000;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    &:hover {
        color: #000;
        text-decoration: none;
    }
}

iframe {
    display: none;
}

.form-group-error {
    label {
        color: $danger;
    }
}

.tooltip-inner {
    text-align: left;
}

@include media-breakpoint-down(sm) {
    .sticky-preferences-ctas {
        position: sticky;
        bottom: 0;
        background: $body-bg;
        z-index: 1000;
    }
}

.headerAlert {
    font-size: 0.9em;
    background-color: #ccc;
    .headerAlertMessage {
        padding: 7.5px 0;
        margin: 0;
        svg {
            margin-right: 5px;
        }
    }
}

.marketColor {
    color: #39b54a;
}

.enablePopupImage {
    width: 500px;
    max-width: 100%;
    border: 1px solid #ccc;
    margin: 40px auto 0px auto;
    display: block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.statsTabs {
    padding: $gutter-half;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    button {
        border: 1px solid #000;
        background-color: #fff;
        text-align: center;
        width: calc(50% - 15px);
        margin-top: 7.5px;
        margin-bottom: 7.5px;
        padding: 7.5px;
        &:nth-child(even) {
            margin-left: 15px;
            margin-right: 0px;
        }

        &:nth-child(odd) {
            margin-left: 0px;
            margin-right: 15px;
        }

        &.active {
            color: $primary;
            font-weight: bold;
            border: 1px solid transparent;
            background-color: #f7f7f7;
        }
    }

    hr {
        display: block;
        background-color: #b7b7b7;
        height: 2px;
        width: 100%;
    }

    @include media-breakpoint-up(sm) {
        padding-left: 45px;

        button {
            background-color: #f7f7f7;
            width: auto;
            margin: 0 !important;
            padding: 7.5px 20px;
            border-top: 1px solid white;
            border-bottom: 1px solid transparent;
            border-left: 1px solid white;
            border-right: 1px solid white;

            &.active {
                background-color: #fff !important;
                font-weight: normal;
                border-top: 1px solid #000;
                border-right: 1px solid #000;
                border-left: 1px solid #000;
                border-bottom: 1px solid transparent;
                background-color: #f7f7f7;
            }
        }

        hr {
            height: 1px !important;
            margin: 0 0 0 -30px;
            z-index: -9;
            border: 0;
            background-color: #000;
        }
    }
}

.statsCard {
    height: 100%;
    margin-top: 0.5rem;
    border: 1px solid #e4e4e4;
    .card-header {
        border-bottom: 1px solid #e4e4e4;
    }
    .card-body {
        background-color: #f7f7f7;
    }
}

.card {
    .card-body {
        .info {
            position: absolute;
            z-index: 3;
            top: 3%;
            right: 3%;
            background-color: #d9d9d9;
            color: $primary;
            font-weight: bold;
            padding: 4px 8px;
            font-size: 12px;
        }
    }
}

sub,
sup {
    font-size: 60%;
}


.PhoneInput {
    display: flex !important;
}

.PhoneInput .PhoneInputInput,
.PhoneInput .PhoneInputInput:focus,
.PhoneInput .PhoneInputInput:focus-visible {
    border: 0;
    outline: 0;
    text-align: right;
    color: black;
}
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
            var(--PhoneInputCountryFlag-borderColor),
        inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
            var(--PhoneInputCountryFlag-borderColor) !important;
}
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border + .PhoneInputCountrySelectArrow {
    color: var(--PhoneInputCountrySelectArrow-color) !important;
}
.userSubmitSpinner {
    background-color: #fff !important;
    border: none !important;
}
.userInputs .form-control:disabled,
.userInputs .form-control input:disabled {
    background-color: #fff !important;
}
.userInputs .form-control {
    color: black !important;
}

li.isLink:has(.contractNameSwitcher):hover {
    text-decoration: none !important;
    cursor: default;
}
