@import '../../../../head.scss';

.accessValidation-wrapper{

    &.accessValidation-cgv header, &.accessValidation-cgv main, &.accessValidation-cgv .accessValidation-container main label{
        font-size: 11px;


    }

    &.accessValidation-cgv {
        .accessValidation-container{
        
            @include media-breakpoint-up(sm) {
                width: 600px;
            }
        }
    }

    height: 100vh;
    display: flex;
    font-family: sans-serif;
    font-size: 14px;
    letter-spacing:0;

    h1,h2,h3{
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 16px;
        margin-top: 20px;
    }

    input[type=checkbox]{
        accent-color: $primary;
    }

    .accessValidation-container{
        
        padding: 25px 0;
        @include media-breakpoint-up(sm) {
            width: 450px;



            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: auto;
            box-shadow: rgba(0, 0, 0, 0.12) 0px 12px 40px 0px;
        }

        .form-control{
            border:1px solid #bebcbc;
        }
    
        header{
            padding: 0  40px;
            margin:0;
            text-align: center;
            .logo{
                svg{
                    height:45px;
                }
            }
   
            p{
                line-height: 21px;
                margin: 0;
            }
        }
    
        main{
            padding: 0 40px;
            .form-group{
                padding-left: 30px;
            }
            label{
                display: inline-block;
                margin-bottom: 0.5rem;
                position: relative;
                bottom: -18px;
                z-index: 99;
                background: white;
                left: 11px;
                font-size: 12px;
                padding: 0 5px;
                color: #666;
            }
            button{
                margin: 20px auto 0 auto;
            }
        }

        .help{

            h3{
                font-size: 24px;
                font-weight: normal;
            }
            hr{
                border-top: 1px solid #bebcbc;
            }
            padding: 10px 40px;
            text-align: center;
            p{
                margin: 0;
            }
        }

        .PhoneInput{
            border: 1px solid #bebcbc;
            padding: 7px 10px;
            .PhoneInputInput{
                text-align: left;
            }
        }

        ul{
            margin-left: 0;
            padding-left: 2em;
            list-style-type: disc;
        }
    }

    .onboarding-steps{
        display: flex;
        list-style-type: none;
        position: relative;
        justify-content: space-between;
        padding: 0 !important;
        list-style-type: none !important;
        margin: $gutter-half 0;
        
        li{
            line-height: 24px;
            width: 24px;
            height: 24px;
            background-color: rgb(190, 188, 188);
            color:white;
            &.current{
                background-color: $primary;
            }
            z-index: 100;
        }

        &::before{
            content: "";
            position: absolute;
            width: 100%; 
            border-bottom:1px solid rgb(190, 188, 188);
            top: 12px;
        }

        &:after{
            content: "";
            position: absolute;
            width: 0; 
            border-bottom:1px solid $primary;
            top: 12px;
        }

        &.current-step-2:after{
            width: 50%; 
        }
        &.current-step-3:after{
            width: 100%; 
        }
    }
}